import React, { Component } from "react";

import Layout from '../components/Layout'
import PopUp from '../components/PopUp'

class IndexPage extends Component {
  render() {
  return (
    <div>
      <Layout>
        <PopUp /> 
      </Layout>
    </div>
    )
  }
}

export default IndexPage
