import { Link } from "gatsby";
import React, { useState } from "react";

import "./styles.css";

const PopUp = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [service, setService] = useState("");

  const listService = [
    { id: 1, name: "Tipos de serviço" },
    { id: 2, name: "Vigilância Desarmada" },
    { id: 3, name: "Vigilância Armada" },
    { id: 4, name: "Vigilantes em eventos" }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(name, telephone, email, service);
    if(service === "Tipos o serviço") {
        setService("");
    } else {
        if (name === "" || telephone === "" || email === "" || service === "") {
          alert("Favor complete todos os campos ☺️");
          setName("");
          setService("");
        } else {
          let message = encodeURI(
            `Olá, meu nome é ${name} meu telefone é ${telephone} meu email é ${email} e gostaria de solicitar um orçamento do serviço de ${service}`
          );
    
          //Padrão
          window.location.replace(`https://wa.me/+5518997349750?text=${message}`);

          //Test Me
          // window.location.replace(`https://wa.me/+5567992656801?text=${message}`);

          //Test Felipe Marketing
          //window.location.replace(`https://wa.me/+5518998022265?text=${message}`);
    
          setName("");
          setTelephone("");
          setEmail("");
          setService("");
        }
      };
    }

  return (
    <div className="pop-info">
      <div className="header-text">
        <span>Fale conosco agora!</span>
      </div>
      <section>
        <form onSubmit={handleSubmit}>
        <input 
            type="text" 
            name="name" 
            placeholder="Digite seu Nome*" 
            value={name}
            onChange={(e) => setName(e.target.value)}
        />
        <input 
            type="text" 
            name="telefone" 
            placeholder="Digite seu Telefone*" 
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
        />
        <input 
            type="email" 
            name="email" 
            placeholder="Digite seu melhor E-mail*" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
          <select value={service} onChange={(e) => setService(e.target.value)}>
            {listService.map((item, index) => (
              <option value={item.name} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <button type="submit" className="btn-send">Enviar</button>
          <Link to="/" className="btn-cancel">
            Cancelar
          </Link>
        </form>
      </section>
    </div>
  );
};

export default PopUp;
